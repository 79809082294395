@font-face {
    font-family: 'iconfont';  /* Project id 2736943 */
    src: url('//at.alicdn.com/t/c/font_2736943_i63n93t075a.woff2?t=1667182963272') format('woff2'),
         url('//at.alicdn.com/t/c/font_2736943_i63n93t075a.woff?t=1667182963272') format('woff'),
         url('//at.alicdn.com/t/c/font_2736943_i63n93t075a.ttf?t=1667182963272') format('truetype');
  }

.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}
